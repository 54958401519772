<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="40%" transition="dialog-bottom-transition">
            <v-card flat outlined id="card">
                <v-toolbar outlined dense flat color="grey lighten-4">
                    <v-toolbar-title>
                        <span v-if="$store.state.itemDetails.dialogType == 'add'">اختر الملفات</span>
                        <span v-if="$store.state.itemDetails.dialogType == 'newContact'">جهة اتصال جديدة</span>
                        <span v-if="$store.state.itemDetails.dialogType == 'clientTabs'">تبويب الزبون</span>
                        <span v-if="$store.state.itemDetails.dialogType == 'voice'">رسالة صوتية</span>
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                    <v-container>
                        <v-container class="py-5">
                            <v-row>
                                <!-- imageUrl -->
                                <v-col cols="12" md="12" v-if="$store.state.itemDetails.dialogType == 'add'">
                                    <FileUpload
                                        accept="image/*;capture=camera"
                                        formData="files"
                                        endPoint="UploadFile/postUploadFiles"
                                        buttonText="رفع صورة"
                                        icon="true"
                                        v-if="!$global.state.path"
                                    />
                                </v-col>

                                <!-- voice -->
                                <v-col cols="12" md="12" v-if="$store.state.itemDetails.dialogType == 'voice'">
                                    <audio-recorder
                                        :show-download-button="false"
                                        :show-upload-button="false"
                                        :before-recording="callback"
                                        :pause-recording="callback"
                                        :after-recording="callback"
                                        :before-upload="callback"
                                        :successful-upload="callback"
                                    />
                                    <div class="mr-n3" v-if="audioFileUrl">
                                        <audio controls>
                                            <source :src="audioFileUrl" />
                                        </audio>
                                    </div>
                                </v-col>

                                <v-col cols="12" md="12" v-if="$store.state.itemDetails.dialogType == 'newContact'">
                                    <v-autocomplete
                                        v-model="clientId"
                                        :items="clients"
                                        :loading="$global.state.loading"
                                        clearable
                                        item-text="fullName"
                                        item-value="id"
                                        color="indigo"
                                        label="الزبائن"
                                        no-data-text="لا توجد بيانات"
                                        hide-details
                                        prepend-inner-icon="fi fi-br-users"
                                        outlined
                                        filled
                                    />
                                </v-col>

                                <v-col
                                    cols="12"
                                    md="12"
                                    v-if="$store.state.itemDetails.dialogType == 'clientTabs'"
                                >
                                    <v-autocomplete
                                        v-model="tabId"
                                        :items="$global.state.clientTabs"
                                        :loading="$global.state.loading"
                                        clearable
                                        item-text="name"
                                        item-value="id"
                                        color="indigo"
                                        placeholder="التبويبات"
                                        no-data-text="لا توجد بيانات"
                                        hide-details
                                        prepend-inner-icon="mdi-tag-multiple"
                                        outlined
                                        filled
                                    />
                                </v-col>
                            </v-row>
                        </v-container>

                        <!-- actions -->
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn
                                    v-if="$store.state.itemDetails.dialogType == 'add'"
                                    type="submit"
                                    :loading="$global.state.loading"
                                    large
                                    color="primary secondary--text"
                                    class="btn"
                                    :disabled="$store.state.files[0] == null">
                                    إرسال
                                    <v-icon class="mr-2" color="info"> fa-solid fa-paper-plane </v-icon>
                                </v-btn>

                                <v-btn
                                    v-if="$store.state.itemDetails.dialogType == 'voice'"
                                    type="submit"
                                    :loading="$global.state.loading"
                                    large
                                    color="primary secondary--text"
                                    class="btn"
                                    :disabled="audioFile == null">
                                    إرسال
                                    <v-icon class="mr-2" color="info"> fa-solid fa-paper-plane </v-icon>
                                </v-btn>

                                <v-btn
                                    v-if="$store.state.itemDetails.dialogType == 'newContact' || $store.state.itemDetails.dialogType == 'clientTabs'"
                                    type="submit"
                                    :loading="$global.state.loading"
                                    x-large
                                    color="primary secondary--text"
                                    class="btn"
                                    :disabled="!valid">
                                    <span v-if="$store.state.itemDetails.dialogType == 'newContact'">إضافة جهة اتصال جديدة</span>
                                    <span v-if="$store.state.itemDetails.dialogType == 'clientTabs'">حفظ</span>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FileUpload from "@/components/FileUpload.vue";
import { mapState } from "vuex";
export default {
    components: {
        FileUpload,
    },

    data() {
        return {
            valid: false,
            item: {},
            clientId: "",
            clients: [],
            tabId: "",
            audioFile: null,
            audioFileUrl: "",
        };
    },

    computed: {
        ...mapState("auth", ["userData"]),
    },

    mounted() {
        this.reset();
        this.getClients();
        this.getClientTabs();
    },

    methods: {
        async myAudioFile(audioFile) {
            // console.log("myAudioFile: ", audioFile);
            this.audioFile = audioFile;
        },

        callback(data) {
            // // console.log("callback ==>", data);
            this.audioFile = data.blob;
            this.audioFileUrl = data.url;
        },

        reset() {
            this.item = {
                chatId: 0,
                content: "",
                type: 2,
                attachments: [
                    {
                        name: "",
                        type: "",
                        path: "",
                    },
                ],
            };
            this.clientId = "";
            this.audioFile = null;
            this.audioFileUrl = "";
            this.$store.commit("clearFiles");
        },

        submit() {
            this.$refs.form.validate();
            if (this.$store.state.itemDetails.dialogType == "add") {
                this.sendImage();
            }
            if (this.$store.state.itemDetails.dialogType == "clientTabs") {
                this.setClientTabs();
            }
            if (this.$store.state.itemDetails.dialogType == "voice") {
                this.sendVoice();
            } 
            if (this.$store.state.itemDetails.dialogType == "newContact") {
                this.createChat();
            } 
        },

        async sendImage() {
            this.$global.state.loading = true;

            if (this.$store.state.files[0] != null) {
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        this.item.attachments = [
                            {
                                path: res.data.result.fileList[0].path,
                                type: res.data.result.fileList[0].type,
                                name: res.data.result.fileList[0].name,
                            },
                        ];
                    });
                this.item.chatId = this.$global.state.chatId;
                try {
                    const response = await this.$http.post(
                        `Messages`,
                        this.item
                    );
                    const data = await response.data.result;
                    (data.senderUser.group = this.userData.userData.group),
                        await this.$global.state.messages.push(data);
                    this.$eventBus.$emit(`updateScrollMsgbox`);
                    this.close();
                } catch (err) {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                } finally {
                    this.$global.state.loading = false;
                }
            }
        },

        blobToBase64(blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            });
        },

        async sendVoice() {
            this.$global.state.loading = true;

            if (this.audioFile != null) {
                let formData = new FormData();
                formData.append("files", this.audioFile, "voice.mp3");

                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        // console.log(res.data.result);
                        this.item.attachments = [
                            {
                                path: res.data.result.fileList[0].path,
                                type: res.data.result.fileList[0].type,
                                name: res.data.result.fileList[0].name,
                            },
                        ];
                    });

                this.item.chatId = this.$global.state.chatId;
                this.item.type = 3;
                try {
                    const response = await this.$http.post(
                        `Messages`,
                        this.item
                    );
                    const data = await response.data.result;
                    (data.senderUser.group = this.userData.userData.group),
                        await this.$global.state.messages.push(data);
                    this.$eventBus.$emit(`updateScrollMsgbox`);
                    this.close();
                } catch (err) {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                } finally {
                    this.$global.state.loading = false;
                }
            }
        },

        async createChat() {
            try {
                const res = await this.$http.post(
                    `Chats/${this.clientId}`
                );
                // const data = await response.data.result;
                // await this.$global.state.chats.push(data);
                this.close();
                this.$eventBus.$emit(`refreshChats`, res.data.result);
            } catch (err) {
                this.$store.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.$global.state.loading = false;
            }
        },

        async setClientTabs() {
            try {
                const response = await this.$http.delete(
                    `Clients/setTab?userId=${this.$store.state.itemDetails.userId}&tabId=${this.tabId}`
                );
                this.close();
            } catch (err) {
                // this.$store.dispatch("toastification/setToast", { message: `${err.data.message}`, type: "error" });
            } finally {
                this.$store.dispatch("toastification/setToast", {
                    message: `تم تبويب الزبون بنجاح`,
                    type: "success",
                });
                this.$global.state.loading = false;
            }
        },

        async getClients() {
            try {
                this.$global.state.loading = true;
                const res = await this.$http.get(`Clients/list`)
                this.clients = res.data.result;
            } catch (err) {
                // log//
            } finally {
                this.$global.state.loading = false;
            }
        },

        getClientTabs() {
            this.$global.dispatch(`getClientTabs`);
        },

        close() {
            this.reset();
            this.resetValidation();
            this.$store.commit("setDialog");
            this.$store.commit("setItemDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>


<style scoped>
.btn-delete-file {
    padding: 11px;
    font-weight: bold;
}
</style>
